<template>
  <!--      :style="creationData.length > 0 ? '' : 'border-bottom: none'"-->
  <div class="scientificPublication" style="padding-top: 4px">
    <div class="scientificPublication__row justify-space-between">
      <div class="inputDiv" style="width: 500px; margin-right: 0">
        <label>Центр <span style="color: red">*</span></label>
        <!-- <v-text-field
          :rules="[rules.required]"
          v-model="creationData.orgName"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
        ></v-text-field> -->
        <v-autocomplete
          :rules="[rules.required]"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
          v-model="creationData.center.id"
          :items="centersArray"
          @change="getOrganizations">
        </v-autocomplete>
      </div>
      <div class="inputDiv" style="width: 500px; margin-right: 0">
        <label>Организация <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          :disabled="!blockEditingInProcess || !creationData.center.id"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
          v-model="creationData.org.id"
          :items="organizationsArray">
        </v-autocomplete>
      </div>
    </div>
    <v-dialog v-model="invalidData" width="500px">
      <v-card>
        <v-card-title style="margin: 0 auto">
          {{ 'Требуется заполнить все обязательные поля' }}
        </v-card-title>
      </v-card>
    </v-dialog>
    <v-dialog v-model="wrongFileTypeDialog" width="665px">
      <v-card>
        <v-card-title style="margin: 0 auto">
          {{ 'Загруженный формат скана не соответствует расширению .pdf' }}
        </v-card-title>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteConfirmDialog" width="375">
      <v-card>
        <v-card-title>Подтверждение удаления строки</v-card-title>
        <v-card-actions style="justify-content: center">
          <v-btn class="dialog-btn" @click="deleteRow">Да</v-btn>
          <v-btn class="dialog-btn" @click="deleteConfirmDialog = false">Нет</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="scientificPublication__row">
      <!--      <div class="inputDiv" style="width: 549px">-->
      <!--        <label>Кем выдан <span style="color: red">*</span></label>-->
      <!--        <v-text-field-->
      <!--          :rules="[rules.required]"-->
      <!--          v-model="creationData.grantIssuedBy"-->
      <!--          :disabled="!blockEditingInProcess"-->
      <!--          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"-->
      <!--        ></v-text-field>-->
      <!--      </div>-->
      <!--      <div class="inputDiv" style="width: 572px; margin-right: 0;">-->
      <!--        <label>Номер документа <span style="color: red">*</span></label>-->
      <!--        <v-text-field-->
      <!--          :rules="[rules.required]"-->
      <!--          v-model="creationData.grantDocNum"-->
      <!--          :disabled="!blockEditingInProcess"-->
      <!--          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"-->
      <!--        ></v-text-field>-->
      <!--      </div>-->
    </div>
    <div class="scientificPublication__row">
      <div class="fileDiv" style="margin-top: 34px; width: 290px">
        <v-file-input
          prepend-icon="mdi-folder-open-outline"
          v-model="creationData.scanIdSysScan"
          accept="application/pdf"
          :style="creationData.sysScanFileUuid ? 'display: none' : ''"
          placeholder="Выберите файл"
          @change="wrongFileTypeDialogFunc($event)"></v-file-input>
        <div class="file__info" style="margin-right: 12px">
          <p class="file__text" style="min-width: 105px" v-if="!creationData.sysScanFileUuid">
            Скан подтверждающего документа
          </p>
          <p
            class="file__text file__text_link"
            style="min-width: 105px"
            v-else
            @click="
              getFile(
                creationData.sysScanFileUuid,
                creationData.sysScanFileNameOrig,
                creationData.sysScanFileExt,
              )
            ">
            Скан подтверждающего документа
          </p>
          <p class="file__status" v-if="creationData.scanIdSysScan">Загружен</p>
          <p class="file__status file__status_empty" v-else>Не сохранено</p>
        </div>
        <img
          v-if="creationData.sysScanFileUuid"
          src="@/assets/icons/close-red-round.svg"
          alt="delete"
          class="fileDiv__delete"
          @click="deleteFile(creationData.id)" />
      </div>
    </div>
    <div class="btnDiv" style="padding: 22px 0 0 0; border-top: none">
      <button class="usual-btn" :disabled="!blockEditingInProcess" @click="updateInfo">
        Сохранить
      </button>
      <button class="usual-btn usual-btn_grey" @click="getAllBlock">Отмена</button>
      <p class="block__hint">
        <span style="color: red">*</span> Все поля, помеченные звездочкой, обязательны для
        заполнения
      </p>
      <button
        v-if="block6Data.id"
        class="usual-btn usual-btn_delete"
        style="margin-left: auto"
        @click="deleteConfirmFunc">
        Удалить
      </button>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import { mapGetters } from 'vuex';

  export default {
    name: 'Block6Creation',
    data() {
      return {
        loading: false,
        invalidData: false,
        blockEditingInProcess: true,
        deleteConfirmDialog: false,
        wrongFileTypeDialog: false,
        wrongFileType: false,
        centersArray: [],
        organizationsArray: [],
        creationData: {
          id: 0,
          orgName: '',
          center: { id: null },
          org: { id: null },
          grantIssuedBy: '',
          grantDocNum: '',
          scanIdSysScan: null,
        },
        rules: {
          required: (value) => !!value || 'Обязательное поле!',
        },
      };
    },
    props: {
      rowID: {
        type: String,
        default: '',
      },
      block6Data: {
        type: Object,
        default: function () {
          return {};
        },
      },
      applicantBlocked: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters('keycloak', {
        getAuthData: 'getAuthData',
        getUser: 'getUser',
        getUserRoles: 'getUserRoles',
      }),
      fieldsValidation() {
        if (this.creationData.center.id && this.creationData.org.id) {
          return true;
        } else {
          return false;
        }
      },
    },
    created() {
      this.createdFunc();
    },
    methods: {
      cleanOrgArray() {
        this.getOrganizations = [];
      },
      async createdFunc() {
        await this.getCenters();
        await this.getOrganizations();

        if (this.block6Data.id) {
          // проверка на создание/редактирование
          this.creationData = this.block6Data;
        }
      },

      wrongFileTypeDialogFunc(arg) {
        if (arg.type !== 'application/pdf') {
          this.wrongFileTypeDialog = true;
          this.wrongFileType = true;
        } else {
          this.wrongFileType = false;
        }
      },

      async updateInfo() {
        if (this.fieldsValidation && !this.wrongFileType) {
          let url = `/api/2022/valiev/block6/add`;
          if (this.creationData.id !== 0) {
            url = `/api/2022/valiev/block6/update`;
          }
          const formData = new FormData();
          if (
            this.creationData.scanIdSysScan &&
            typeof this.creationData.scanIdSysScan !== 'number'
          ) {
            formData.append('file', this.creationData.scanIdSysScan);
          }
          formData.append(
            'data',
            new Blob(
              [
                JSON.stringify({
                  id: this.creationData.id !== 0 ? this.creationData.id : '',
                  mainId: this.rowID,
                  center: { id: this.creationData.center.id },
                  org: { id: this.creationData.org.id },
                  // orgName: this.creationData.orgName,
                  // "grantDocNum": this.creationData.grantDocNum,
                  // "grantIssuedBy": this.creationData.grantIssuedBy,
                }),
              ],
              {
                type: 'application/json',
              },
            ),
          );
          try {
            const res = await axios.post(url, formData, {
              headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
            });
            if (res.status === 200 && res.data) {
              this.$emit('updateData'); // вызов getAll в корневом файле
              this.blockEditingInProcess = false;
              this.loading = false;
              console.log('updateInfo успех');
            }
          } catch (e) {
            this.loading = false;
            console.log(e);
          }
        } else if (this.wrongFileType) {
          this.wrongFileTypeDialog = true;
        } else {
          this.invalidData = true;
        }
      },

      getAllBlock() {
        this.$emit('updateData');
      },

      deleteConfirmFunc() {
        this.deleteConfirmDialog = true;
      },

      async deleteRow() {
        try {
          const res = await axios.delete(
            `/api/2022/valiev/block6/delete?rowId=${this.block6Data.id}`,
            { headers: { Authorization: `Bearer   ${this.getAuthData.token}` } },
          );
          if (res.status === 200 && res.data) {
            console.log('deleteRow успех');
            this.$emit('updateData');
          }
        } catch (e) {
          console.log(e);
        }
      },

      async deleteFile(ID) {
        this.loading = true;
        let uri = `/api/2022/valiev/block6/scan/delete?rowId=${ID}`;
        try {
          const res = await axios.delete(uri, {
            headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
          });
          if (res.status === 200 && res.data) {
            this.creationData.scanIdSysScan = null;
            this.creationData.sysScanFileUuid = null;
            this.loading = false;
            console.log('deleteFile успех');
          }
        } catch (e) {
          this.loading = false;
          console.log(e);
        }
      },

      async getFile(id, name, ext) {
        let uri = `/api/2022/valiev/blocks/scan/download?sysScanFileUuid=${id}`;
        let responseAxios = await axios.get(uri, {
          responseType: 'blob',
          headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
        });
        this.saveByteArray(`${name}.${ext}`, responseAxios.data, ext);
      },

      async getCenters() {
        try {
          const res = await axios.get(`/api/2022/spr/textValKorolev/get?block=6&position=1`, {
            headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
          });
          if (res.status === 200 && res.data) {
            for (let i = 0; i < res.data.length; i++) {
              this.centersArray.push({
                text: res.data[i].value,
                value: res.data[i].id,
              });
            }
            console.log('getCenters успех');
          }
        } catch (e) {
          console.log(e);
        }
      },

      async getOrganizations() {
        this.organizationsArray = [];

        try {
          const res = await axios.get(`/api/2022/spr/textValKorolev/get?block=6&position=2`, {
            headers: { Authorization: `Bearer ${this.getAuthData.token}` },
          });

          console.log(res);

          if (res.status === 200 && res.data) {
            for (let i = 0; i < res.data.length; i++) {
              this.organizationsArray.push({
                text: res.data[i].value,
                value: res.data[i].id,
              });
            }

            console.log('getOrganizations успех');

            if (this.creationData.center.id == 391) {
              this.organizationsArray = this.organizationsArray.filter(
                (el) => el.value >= 394 && el.value <= 398,
              );
            } else if (this.creationData.center.id == 392) {
              this.organizationsArray = this.organizationsArray.filter((el) => el.value === 399);
            } else {
              this.organizationsArray = this.organizationsArray.filter((el) => el.value > 399);
            }
          }
        } catch (e) {
          console.log(e);
        }
      },

      saveByteArray(reportName, byte, ext) {
        let blob = new Blob([byte], { type: `application/${ext}` });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.target = '_blank'; // вариант открытия в новом окне
        // link.download = reportName; // вариант скачивания
        link.click();
      },
    },
  };
</script>

<style scoped></style>
