<template>
  <div v-if="rowID !== '0'">
    <div v-if="loading" style="min-height: 200px" class="d-flex flex-row align-center">
      <v-progress-linear indeterminate></v-progress-linear>
    </div>
    <div class="blocks" v-else>
      <div
        class="blocks__headerDiv"
        style="display: flex; flex-direction: row"
        :style="!blockOpened ? 'border-bottom: none' : ''"
        @click="blockOpened = !blockOpened">
        <p class="headerText">
          Блок 6. Участие в работах федерального проекта «Подготовка кадров и научного фундамента
          для электронной промышленности»
        </p>
        <img
          v-if="blockOpened"
          src="@/assets/icons/block_opened_blue.svg"
          class="btn_open"
          alt="" />
        <img v-else src="@/assets/icons/block_closed_blue.svg" class="btn_open" alt="" />
      </div>
      <div v-if="blockOpened && block6Data.length > 0">
        <Block6Item
          v-for="(item, j) in block6Data"
          :key="j"
          :value.sync="item"
          :rowID="rowID"
          :konvertConvert="konvertConvert"
          :order="j"
          :applicantBlocked="applicantBlocked"
          :centersArray="centersArray"
          :organizationsArray="organizationsArray"
          @updateData="getAllBlock6" />
      </div>
      <Block6Creation
        v-if="blockOpened && blockEditingInProcess && !applicantBlocked"
        :applicantBlocked="applicantBlocked"
        :rowID="rowID"
        @updateData="getAllBlock6" />
      <div class="btnDiv" v-if="blockOpened && block6Data.length > 0">
        <button v-if="applicantBlocked || isExpert" class="usual-btn usual-btn_disabled">
          Добавить
        </button>
        <button v-else class="usual-btn" @click="addRow">Добавить</button>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import { mapGetters } from 'vuex';
  import Block6Item from '@/components/applicants/applicantForm/valievBlocks/blockItems/Block6Item';
  import Block6Creation from '@/components/applicants/applicantForm/valievBlocks/blockCreation/Block6Creation';

  export default {
    name: 'Block6',
    components: { Block6Creation, Block6Item },
    data() {
      return {
        loading: false,
        blockOpened: false,
        blockEditingInProcess: true,
        block6Data: [],
        centersArray: [],
        organizationsArray: [],
      };
    },
    props: {
      rowID: {
        type: String,
        default: '',
      },
      applicantBlocked: {
        type: Boolean,
        default: false,
      },
      konvertConvert: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters('keycloak', {
        getAuthData: 'getAuthData',
        isExpert: 'isExpert',
        // isCurator: 'isCurator',
      }),
    },
    created() {
      if (this.rowID !== '0') {
        this.getAllBlock6();
        this.getCenters();
        this.getOrganizations();
      }

      if (this.isExpert) {
        this.blockOpened = true;
      }
    },
    methods: {
      async getAllBlock6() {
        this.loading = true;
        this.block6Data = [];
        try {
          const res = await axios.get(`/api/2022/valiev/block6/get?mainId=${this.rowID}`, {
            headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
          });
          console.log(res);
          if (res.status === 200 && res.data) {
            if (res.data.length > 0) {
              this.block6Data = res.data;
              this.blockEditingInProcess = false;
            } else {
              this.blockEditingInProcess = true;
            }
            console.log('getAllBlock6 успех');
            this.loading = false;
          }
        } catch (e) {
          this.loading = false;
          console.log(e);
        }
      },

      async getCenters() {
        try {
          const res = await axios.get(`/api/2022/spr/textValKorolev/get?block=6&position=1`, {
            headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
          });
          if (res.status === 200 && res.data) {
            for (let i = 0; i < res.data.length; i++) {
              this.centersArray.push({
                text: res.data[i].value,
                value: res.data[i].id,
              });
            }
            console.log('getCenters успех');
          }
        } catch (e) {
          console.log(e);
        }
      },

      async getOrganizations() {
        try {
          const res = await axios.get(`/api/2022/spr/textValKorolev/get?block=6&position=2`, {
            headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
          });
          console.log(res);
          if (res.status === 200 && res.data) {
            for (let i = 0; i < res.data.length; i++) {
              this.organizationsArray.push({
                text: res.data[i].value,
                value: res.data[i].id,
              });
            }
            console.log('getOrganizations успех');
          }
        } catch (e) {
          console.log(e);
        }
      },

      addRow() {
        this.blockEditingInProcess = true;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
