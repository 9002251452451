<template>
  <!--      :style="creationData.length > 0 ? '' : 'border-bottom: none'"-->
  <div class="scientificPublication" style="padding-top: 4px">
    <div class="scientificPublication__row">
      <div class="inputDiv" style="width: 885px">
        <label>Название патента <span style="color: red">*</span></label>
        <v-text-field
          :rules="[rules.required]"
          v-model="creationData.patentPatentName"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"></v-text-field>
      </div>
      <div class="inputDiv" style="width: 234px; margin-right: 0">
        <label>Тип патента <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
          v-model="creationData.patentPatentType"
          :items="patentTypeArray"></v-autocomplete>
      </div>
    </div>
    <v-dialog v-model="invalidData" width="500px">
      <v-card>
        <v-card-title style="margin: 0 auto">
          {{ 'Требуется заполнить все обязательные поля' }}
        </v-card-title>
      </v-card>
    </v-dialog>
    <v-dialog v-model="wrongFileTypeDialog" width="665px">
      <v-card>
        <v-card-title style="margin: 0 auto">
          {{ 'Загруженный формат скана не соответствует расширению .pdf' }}
        </v-card-title>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteConfirmDialog" width="375">
      <v-card>
        <v-card-title>Подтверждение удаления строки</v-card-title>
        <v-card-actions style="justify-content: center">
          <v-btn class="dialog-btn" @click="deleteRow">Да</v-btn>
          <v-btn class="dialog-btn" @click="deleteConfirmDialog = false">Нет</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="scientificPublication__row">
      <div class="inputDiv" style="width: 445px">
        <label>Наличие соавторов <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          v-model="creationData.articleHasCoauthor"
          :items="articleCoauthorsOptions"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"></v-autocomplete>
      </div>
      <div class="inputDiv" style="width: 420px">
        <label>Номер документа <span style="color: red">*</span></label>
        <v-text-field
          :rules="[rules.required]"
          v-model="creationData.patentPatentNum"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"></v-text-field>
      </div>
      <div class="inputDiv" style="width: 234px; margin-right: 0">
        <label>Дата выдачи <span style="color: red">*</span></label>
        <vue-ctk-date-time-picker
          id="DateTimePicker"
          v-model="creationData.patentPatentDate"
          :no-button-now="true"
          only-date
          format="YYYY-MM-DD"
          formatted="LL"
          color="#00599B"
          button-color="#00599B"
          min-date="2022-01-01"
          max-date="2022-12-31"
          label=""
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
          :disabled="!blockEditingInProcess" />
      </div>
    </div>
    <div class="scientificPublication__row">
      <div
        class="inputDiv"
        style="width: 1145px; margin-right: 0"
        v-if="creationData.articleHasCoauthor">
        <label>Соавторы <span style="color: red">*</span></label>
        <v-text-field
          :rules="[rules.required]"
          v-model="creationData.articleCoauthor"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"></v-text-field>
      </div>
    </div>
    <div class="scientificPublication__row">
      <div class="fileDiv" style="margin-top: 34px; width: 180px; margin-right: 25px">
        <v-file-input
          prepend-icon="mdi-folder-open-outline"
          v-model="creationData.scanIdSysScan"
          accept="application/pdf"
          :style="creationData.sysScanFileUuid ? 'display: none' : ''"
          placeholder="Выберите файл"
          @change="wrongFileTypeDialogFunc($event)"></v-file-input>
        <div class="file__info">
          <p class="file__text" style="min-width: 105px" v-if="!creationData.sysScanFileUuid">
            Скан документа
          </p>
          <p
            class="file__text file__text_link"
            style="min-width: 105px"
            v-else
            @click="
              getFile(
                creationData.sysScanFileUuid,
                creationData.sysScanFileNameOrig,
                creationData.sysScanFileExt,
              )
            ">
            Скан документа
          </p>
          <p class="file__status" v-if="creationData.scanIdSysScan">Загружен</p>
          <p class="file__status file__status_empty" v-else>Не сохранено</p>
        </div>
        <img
          v-if="creationData.sysScanFileUuid"
          src="@/assets/icons/close-red-round.svg"
          alt="delete"
          class="fileDiv__delete"
          @click="deleteFile(creationData.id)" />
      </div>
      <div
        class="inputDiv"
        style="width: 682px; margin-right: 0"
        v-if="creationData.patentPatentType === 9">
        <label>URL <span style="color: red">*</span></label>
        <v-text-field
          :rules="[rules.required]"
          v-model="creationData.patentUrl"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"></v-text-field>
      </div>
    </div>
    <!--    только для российских патентов    -->
    <!--    <div class="scientificPublication__row" style="margin-top: 17px;"-->
    <!--         v-if="creationData.patentPatentType === 10">-->
    <!--      <v-checkbox-->
    <!--        v-model="creationData.patentConfirmMark"-->
    <!--      >-->
    <!--        <template v-slot:label>-->
    <!--          <div>-->
    <!--            Соответствует-->
    <!--            <a-->
    <!--              target="_blank"-->
    <!--              :href="`https://www1.fips.ru/fips_servl/fips_servlet?DB=RUPAT&DocNumber=${creationData.patentPatentNum}`"-->
    <!--              @click.stop-->
    <!--            >-->
    <!--              Документу на сайте ФИПС-->
    <!--            </a>-->
    <!--          </div>-->
    <!--        </template>-->
    <!--      </v-checkbox>-->
    <!--      <div class="warningDiv">-->
    <!--        <img src="@/assets/icons/warning.svg">-->
    <!--        <span class="">Проверьте соответствие данных о выданном документе с информацией на сайте ФИПС по указанной ссылке.</span>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="btnDiv" style="padding: 22px 0 0 0; border-top: none">
      <button class="usual-btn" :disabled="!blockEditingInProcess" @click="updateInfo">
        Сохранить
      </button>
      <button class="usual-btn usual-btn_grey" @click="getAllBlock">Отмена</button>
      <p class="block__hint">
        <span style="color: red">*</span> Все поля, помеченные звездочкой, обязательны для
        заполнения
      </p>
      <button
        v-if="block2Data.id"
        class="usual-btn usual-btn_delete"
        style="margin-left: auto"
        @click="deleteConfirmFunc">
        Удалить
      </button>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import { mapGetters } from 'vuex';
  import moment from 'moment';

  moment.locale('ru');
  import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
  import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

  export default {
    name: 'Block2Creation',
    components: { VueCtkDateTimePicker },
    data() {
      return {
        loading: false,
        invalidData: false,
        blockEditingInProcess: true,
        deleteConfirmDialog: false,
        wrongFileTypeDialog: false,
        wrongFileType: false,
        creationData: {
          id: 0,
          patentPatentType: null,
          patentPatentName: '',
          articleHasCoauthor: null,
          articleCoauthor: '',
          patentPatentNum: '',
          patentPatentDate: '',
          patentUrl: '',
          patentConfirmMark: false,
          scanIdSysScan: null,
        },
        patentTypeArray: [],
        articleCoauthorsOptions: [
          { text: 'Да', value: true },
          { text: 'Нет', value: false },
        ],
        rules: {
          required: (value) => !!value || 'Обязательное поле!',
        },
      };
    },
    props: {
      rowID: {
        type: String,
        default: '',
      },
      block2Data: {
        type: Object,
        default: function () {
          return {};
        },
      },
      applicantBlocked: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters('keycloak', {
        getAuthData: 'getAuthData',
        getUser: 'getUser',
        getUserRoles: 'getUserRoles',
      }),
      fieldsValidation() {
        if (
          this.creationData.patentPatentName &&
          this.creationData.patentPatentType &&
          ((this.creationData.articleHasCoauthor === true && this.creationData.articleCoauthor) ||
            this.creationData.articleHasCoauthor === false) &&
          this.creationData.patentPatentNum &&
          this.creationData.patentPatentDate &&
          (this.creationData.patentPatentType === 10 || this.creationData.patentUrl)
        ) {
          return true;
        } else {
          return false;
        }
      },
      // maxDate() {
      //   let x = new Date(Date.now()).toLocaleDateString().replace(/[.]/g, "-");
      //   let years = x.slice(-4);
      //   let days = x.slice(0, 2);
      //   let months = x.slice(2, -4);
      //   return `${years}${months}${days}`
      // }
    },
    created() {
      this.createdFunc();
    },
    methods: {
      async createdFunc() {
        await this.getSprPatentType();

        if (this.block2Data.id) {
          // проверка на создание/редактирование
          this.creationData = this.block2Data;
        }
      },
      wrongFileTypeDialogFunc(arg) {
        if (arg.type !== 'application/pdf') {
          this.wrongFileTypeDialog = true;
          this.wrongFileType = true;
        } else {
          this.wrongFileType = false;
        }
      },

      async getSprPatentType() {
        try {
          const res = await axios.get(`/api/2022/spr/textVal/get?block=2&position=1`, {
            headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
          });
          if (res.status === 200 && res.data) {
            for (let i = 0; i < res.data.length; i++) {
              this.patentTypeArray.push({
                text: res.data[i].value,
                value: res.data[i].id,
              });
            }
            console.log('getSprPatentType успех');
          }
        } catch (e) {
          console.log(e);
        }
      },

      async updateInfo() {
        if (this.fieldsValidation && !this.wrongFileType) {
          let url = `/api/2022/valiev/block2/add`;
          if (this.creationData.id !== 0) {
            url = `/api/2022/valiev/block2/update`;
          }
          const formData = new FormData();
          if (
            this.creationData.scanIdSysScan &&
            typeof this.creationData.scanIdSysScan !== 'number'
          ) {
            formData.append('file', this.creationData.scanIdSysScan);
          }
          formData.append(
            'data',
            new Blob(
              [
                JSON.stringify({
                  id: this.creationData.id !== 0 ? this.creationData.id : '',
                  mainId: this.rowID,
                  patentPatentType: this.creationData.patentPatentType,
                  patentPatentName: this.creationData.patentPatentName,
                  // "patentAuthors": this.creationData.patentAuthors,
                  articleHasCoauthor: this.creationData.articleHasCoauthor,
                  articleCoauthor: this.creationData.articleHasCoauthor
                    ? this.creationData.articleCoauthor
                    : '',
                  patentPatentNum: this.creationData.patentPatentNum,
                  patentPatentDate: this.creationData.patentPatentDate,
                  patentUrl:
                    this.creationData.patentPatentType === 9 ? this.creationData.patentUrl : '',
                  patentConfirmMark:
                    this.creationData.patentPatentType === 10
                      ? this.creationData.patentConfirmMark
                      : false,
                }),
              ],
              {
                type: 'application/json',
              },
            ),
          );
          try {
            const res = await axios.post(url, formData, {
              headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
            });
            if (res.status === 200 && res.data) {
              this.$emit('updateData'); // вызов getAll в корневом файле
              this.blockEditingInProcess = false;
              this.loading = false;
              console.log('updateInfo успех');
            }
          } catch (e) {
            this.loading = false;
            console.log(e);
          }
        } else if (this.wrongFileType) {
          this.wrongFileTypeDialog = true;
        } else {
          this.invalidData = true;
        }
      },

      getAllBlock() {
        this.$emit('updateData');
      },

      deleteConfirmFunc() {
        this.deleteConfirmDialog = true;
      },

      async deleteRow() {
        try {
          const res = await axios.delete(
            `/api/2022/valiev/block2/delete?rowId=${this.block2Data.id}`,
            { headers: { Authorization: `Bearer   ${this.getAuthData.token}` } },
          );
          if (res.status === 200 && res.data) {
            console.log('deleteRow успех');
            this.$emit('updateData');
          }
        } catch (e) {
          console.log(e);
        }
      },

      async deleteFile(ID) {
        this.loading = true;
        let uri = `/api/2022/valiev/block2/scan/delete?rowId=${ID}`;
        try {
          const res = await axios.delete(uri, {
            headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
          });
          if (res.status === 200 && res.data) {
            this.creationData.scanIdSysScan = null;
            this.creationData.sysScanFileUuid = null;
            this.loading = false;
            console.log('deleteFile успех');
          }
        } catch (e) {
          this.loading = false;
          console.log(e);
        }
      },

      async getFile(id, name, ext) {
        let uri = `/api/2022/valiev/blocks/scan/download?sysScanFileUuid=${id}`;
        let responseAxios = await axios.get(uri, {
          responseType: 'blob',
          headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
        });
        this.saveByteArray(`${name}.${ext}`, responseAxios.data, ext);
      },

      saveByteArray(reportName, byte, ext) {
        let blob = new Blob([byte], { type: `application/${ext}` });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.target = '_blank'; // вариант открытия в новом окне
        // link.download = reportName; // вариант скачивания
        link.click();
      },
    },
  };
</script>

<style scoped></style>
